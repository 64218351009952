/* eslint-disable max-depth */
import _each from "lodash/each";
import facebookEvents from "web/script/analytics/facebook-events";
import fbq from "web/script/embedded/facebook-tag-manager";
import environment from "web/script/modules/environment";

/**
 * This handles all events from our analytics pipeline for facebook_events. The send method will
 * collect any relevant events that have been passed in, and push the track to the FB event queue,
 * OR, create a static image pixel to track.
 *
 * The reason for the static pixel is because we cannot wait until the tag has successfully been
 * sent to FB using their standard method, so to ensure it is sent in some circumstances (ie.
 * the affiliate lead page), we create an image that we know is successful or not.
 */
export default {
    accountId: "684955101576177",
    appAccountId: "152784791451625",
    checkoutAccountId: "774393373796076",

    init: function (analytics) {
        let deviceUid = analytics.getDeviceUid();
        fbq("set", "autoConfig", false, this.accountId);
        fbq("init", this.accountId, { extern_id: deviceUid });
        fbq("init", this.checkoutAccountId, { extern_id: deviceUid });
    },

    send: function (data) {
        // this will contain any promises we generate
        let promises = [];

        _each(data, (event) => {
            let events = [];
            switch (event.type) {
                case "page_view":
                    // Send Page View events on all pages apart from Lead page
                    if (event.data.page_type !== "lead") {
                        events.push({
                            eventName: facebookEvents.page_view.name,
                            eventData: {
                                life_cycle_stage: event.data.life_cycle_stage,
                            },
                        });
                    }
                    // Send View Content FB Pixel Event when product ID isn't null
                    if (event.data.product_id) {
                        events.push({
                            eventName: facebookEvents.view_content.name,
                            eventData: {
                                content_ids: [event.data.product_id],
                                content_type: "product",
                            },
                        });
                    }
                    break;

                case "event":
                    events = this.getEventProps(event.data);
                    break;

                default:
                    break;
            }

            events.forEach((event) => {
                // if we've specified to wait, we need to create an image and push a new promise
                if (event.wait) {
                    let accountIds = [this.accountId];
                    if (event.isApp) {
                        accountIds.push(this.appAccountId);
                    }
                    if (!event.affiliateOnly) {
                        accountIds.push(this.checkoutAccountId);
                    }
                    // any custom data needs to be put into a custom data format
                    // &cd[key]=value&cd[key2]=value2...
                    let customDataString = this.getCustomDataString(event.eventData);
                    accountIds.forEach(function (accountId) {
                        promises.push(
                            new Promise((resolve) => {
                                // create the image
                                let img = document.createElement("img");
                                img.onerror = img.onload = resolve;

                                // set the image src
                                img.src = `https://www.facebook.com/tr?id=${accountId}&ev=${event.eventName}${customDataString}`;

                                // you've got three seconds
                                window.setTimeout(resolve, 3000);
                            })
                        );
                    });
                } else {
                    // If the event is one of the Facebook Standard Events then call the
                    // pixel using `track`, otherwise call the pixel using `trackCustom`
                    // if the event is custom.
                    let trackType = facebookEvents.EVENT_NAMES.includes(event.eventName)
                        ? "track"
                        : "trackCustom";

                    // do it the regular way, less intensive
                    if (!event.affiliateOnly) {
                        fbq(trackType, event.eventName, event.eventData);
                    } else {
                        // these options enable you to selectively fire events for a
                        // specific Pixel, when multiple Pixels are initialized on the page
                        let trackSingleType =
                            trackType === "track" ? "trackSingle" : "trackSingleCustom";
                        fbq(
                            trackSingleType,
                            this.accountId,
                            event.eventName,
                            event.eventData || {}
                        );
                    }
                }
            });
            return;
        });
        return promises.length > 0 ? Promise.all(promises) : Promise.resolve();
    },

    getCustomDataString(customData) {
        let customDataString = "";

        if (!customData) {
            return customDataString;
        }

        for (let customKey in customData) {
            if (!customData.hasOwnProperty(customKey)) {
                continue;
            }

            let customValue = customData[customKey];
            customDataString = `${customDataString}&cd[${customKey}]=${customValue}`;
        }

        return customDataString;
    },

    getEventProps(data) {
        let events = [];
        const category = data.category;
        const action = data.action;
        const label = data.label;
        const facebookCategory = facebookEvents[category];
        const predictedRPL = environment.get("predictedTrackValue");
        switch (category) {
            case "cart":
                if (action === facebookCategory.action && label === facebookCategory.label) {
                    events.push({
                        eventName: facebookCategory.name,
                        eventData: {
                            content_ids: [data.customData.product_id],
                            content_type: "product",
                            value: data.customData.price,
                            currency: "USD",
                        },
                        wait: true,
                    });
                }
                break;

            case "affiliate":
                if (action === facebookCategory.action) {
                    if (predictedRPL) {
                        // send a Purchase FB event
                        events.push({
                            eventName: facebookCategory.purchase.name,
                            eventData: {
                                content_ids: [label],
                                content_type: "product",
                                value: predictedRPL && predictedRPL * 100,
                                currency: "USD",
                            },
                            wait: true,
                            affiliateOnly: true,
                            isApp: true,
                        });
                    }
                    // send a Lead FB event
                    events.push({
                        eventName: facebookCategory.lead.name,
                        eventData: {},
                        wait: true,
                        affiliateOnly: true,
                    });
                }
                break;

            case "signup":
                if (action === facebookCategory.action && label === facebookCategory.label) {
                    events.push({
                        eventName: facebookCategory.name,
                        eventData: {},
                    });
                }
                break;

            case "aspirer_acquisition_campaign":
                if (action === facebookCategory.action && label === facebookCategory.label) {
                    const facebookCategoryNames = facebookCategory.name;
                    facebookCategoryNames.forEach((facebookCategoryName) => {
                        events.push({
                            eventName: facebookCategoryName,
                            eventData: {},
                        });
                    });
                }
                break;

            default:
                break;
        }

        return events;
    },
};
