import React, { useState } from "react";
import { Conditional } from "web/react/components/conditional";
import { LeadLink } from "web/react/components/lead-link";
import { Promo } from "web/react/components/promo-offer/pdp-promo";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Button } from "web/react/emo/button";
import { HStack } from "web/react/emo/h-stack";
import { ListItem } from "web/react/emo/list-item/list-item";
import { Pill } from "web/react/emo/pill";
import { Price } from "web/react/emo/price";
import { Retailer } from "web/react/emo/retailer";
import { PromoTag, Sticker, StickerProps } from "web/react/emo/sticker";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import { BuyButton } from "web/react/pages/product/in-stock/in-stock-product-page/buy-button";
import { useInStockProductPageContext } from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { sharedStyles as styles } from "web/react/pages/product/in-stock/in-stock-product-page/product-price-comparison/price-comparison-table";
import {
    getPromotion,
    getShippingInformation,
} from "web/react/pages/product/in-stock/in-stock-product-page/utils";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import { ProductBuyOptionsSerializer, ProductScreenSize } from "web/types/serializers";

export function Row({
    index,
    buyOption,
    stickerType,
    termsUrl,
}: {
    index: number;
    buyOption: ProductBuyOptionsSerializer;

    stickerType?: StickerProps["name"];
    termsUrl: string;
}): JSX.Element {
    const shipping = getShippingInformation(buyOption);
    const {
        product,
        sizePicker: { showSizePicker, userDefaultSchema, setSelectedSizeOption },
        comparison: { isComparePriceOpen, closeCompareModal },
        checkoutModal: { setIsModalOpen },
    } = useInStockProductPageContext();

    const pillList = buyOption.sizes.slice(0, 3);
    const promotion = getPromotion(product, buyOption, termsUrl);
    const [showPromo, setShowPromo] = useState(true);

    const openCheckoutModal = (): void => {
        setIsModalOpen(true);
        if (isComparePriceOpen) {
            closeCompareModal();
        }
        // reset selected size option to null on close/open
        setSelectedSizeOption(null);
    };

    // ENRICH-3322: Show track link for `See retailer for available sizes` text
    const showTrackLinkForAvailableSizes = environment.getFeature(
        "act_show_link_for_retailer_available_sizes"
    );

    const priceArea = (
        <View className={styles.priceArea}>
            <Price
                fullPrice={buyOption.price}
                inStock={Boolean(buyOption.in_stock)}
                salePrice={buyOption.sale_price}
                size={"lg"}
            />
            {stickerType && (
                <span className={styles.sticker["desktop"]}>
                    <Sticker name={stickerType} />
                </span>
            )}
            {!!promotion && (
                <div className={styles.promoCta["desktop"]}>
                    <PromoTag
                        onStickerClick={() => {
                            setShowPromo(!showPromo);
                        }}
                        hide={showPromo}
                    />
                </div>
            )}
        </View>
    );

    const retailerArea = (
        <View className={styles.retailerArea}>
            <span>
                <LeadLink
                    href={buyOption.affiliate_url}
                    rel="noreferrer"
                    reason="retailer-link"
                    openInNewTab
                >
                    <span className={styles.retailerName["noFrom"]}>
                        <Text color="primary" textStyle="body-2">
                            {buyOption.retailer_name}
                        </Text>
                    </span>
                    <span className={styles.retailerName["withFrom"]}>
                        <Retailer
                            retailerName={buyOption.retailer_name}
                            fromSpanStyle={"capitalize"}
                        />
                    </span>
                </LeadLink>
            </span>
            {stickerType && (
                <span className={styles.sticker["tablet"]}>
                    <Sticker name={stickerType} />{" "}
                </span>
            )}
            {!!promotion && (
                <div className={styles.promoCta["tablet"]}>
                    <PromoTag
                        onStickerClick={() => {
                            setShowPromo(!showPromo);
                        }}
                        hide={showPromo}
                    />
                </div>
            )}
        </View>
    );

    const shippingInfoArea = (
        <View className={styles.shippingInfoArea}>
            <ul>
                {shipping.map((shippingInfo, i) => (
                    <ListItem key={i} variant={"primary"} size="xs" flexWrapperHeight align="start">
                        <SVGIcon
                            name={shippingInfo.isFree ? "tick" : "plus"}
                            className={styles.tickIcon}
                            overrideStyle
                        />
                        <Text
                            textStyle={"body-2"}
                            color={"secondary"}
                            className={styles.shippingInfo}
                            as="span"
                        >
                            {shippingInfo.info}
                        </Text>
                    </ListItem>
                ))}
            </ul>
        </View>
    );

    const sizePillsArea = (
        <View className={styles.sizePillsArea}>
            <div>
                <HStack spacing="xxs" wrap>
                    {buyOption.is_icon ? (
                        <>
                            {pillList.map((size: ProductScreenSize) => {
                                const displayedSize = size.localized_sizes
                                    ? size.localized_sizes[userDefaultSchema].display_size
                                    : size.display_size;
                                return (
                                    <Pill key={`${size.link_id}-${displayedSize}`}>
                                        {displayedSize}
                                    </Pill>
                                );
                            })}
                            <Conditional check={buyOption.sizes.length > 3}>
                                <Pill>
                                    {/* "+ {count} More" */}
                                    {gettext("product.buy_area.sizes_more_count.label", {
                                        count: buyOption.sizes.length - 3,
                                    })}
                                </Pill>
                            </Conditional>
                        </>
                    ) : showTrackLinkForAvailableSizes ? (
                        <LeadLink
                            href={buyOption.affiliate_url}
                            rel="noreferrer"
                            reason="retailer-available-sizes"
                            openInNewTab
                        >
                            {/* "See retailer for available sizes" */}
                            <Button
                                title={gettext(
                                    "in_stock_product.compare_prices.see_retailer_available_sizes"
                                )}
                                className={styles.retailerSizesButton}
                                variant="secondary"
                            />
                        </LeadLink>
                    ) : (
                        <Pill>
                            {/* "See retailer for available sizes" */}
                            {gettext(
                                "in_stock_product.compare_prices.see_retailer_available_sizes"
                            )}
                        </Pill>
                    )}
                </HStack>
            </div>
        </View>
    );

    const buttonArea = (
        <View className={styles.buttonArea}>
            {!!promotion && (
                <div className={styles.promoCta["mobile"]}>
                    <PromoTag
                        onStickerClick={() => {
                            setShowPromo(!showPromo);
                        }}
                        hide={showPromo}
                    />
                </div>
            )}
            {showSizePicker && buyOption.is_icon ? (
                <BuyButton
                    variant={buyOption.is_icon ? "checkout" : "affiliate"}
                    action={"buy"}
                    buyOption={buyOption}
                    buyOptionPosition={index}
                    isComparison
                />
            ) : (
                <BuyButton
                    variant={buyOption.is_icon ? "checkout" : "affiliate"}
                    action={"openModal"}
                    openModal={openCheckoutModal}
                    buyOption={buyOption}
                    buyOptionPosition={index}
                    isComparison
                />
            )}
        </View>
    );

    return (
        <View className={styles.comparisonRowWrapper}>
            <div data-testid={stickerType}>
                <View
                    className={styles.comparisonRow["option"]}
                    paddingY={"sm"}
                    paddingX={"md"}
                    marginBottom={{ sm: "md", md: "none" }}
                >
                    {priceArea}
                    {retailerArea}
                    {shippingInfoArea}
                    {sizePillsArea}
                    {buttonArea}
                </View>
                {showPromo && (
                    <div className={styles.promotionArea}>
                        <Promo promotion={promotion} variant="row" />
                    </div>
                )}
            </div>
        </View>
    );
}
