import React from "react";
import { StoryCard } from "web/react/emo/story-card";
import { useStoryAnalytics } from "web/react/hooks/use-story-analytics";
import { SecondaryStorySerializer } from "web/types/serializers";
import * as styles from "./secondary-story-card.css";

interface SecondaryStoryProps extends SecondaryStorySerializer {
    railPosition: number;
    position: number;
    lazy?: boolean;
    alt?: string;
}

export function SecondaryStoryCard({
    id: componentId,
    title,
    caption,
    url,
    component_label: componentLabel,
    image_url: imageSrc,
    is_sponsored: isSponsored,
    position,
    railPosition,
    lazy = false,
    alt,
}: SecondaryStoryProps): React.ReactElement {
    const { containerRef, sendClickEvent } = useStoryAnalytics({
        label: "secondary_story",
        componentId,
        componentLabel,
        position,
        isSponsored,
        railPosition,
    });

    return (
        <div ref={containerRef} className={styles.container} data-testid="secondary-story">
            <StoryCard
                title={title}
                caption={caption}
                url={url}
                image_url={imageSrc}
                is_sponsored={isSponsored}
                lazy={lazy}
                alt={alt}
                sendClickEvent={sendClickEvent}
            />
        </div>
    );
}
