import React from "react";
import Accordion from "web/react/components/__LEGACY__/accordion/accordion";
import { LeadLink } from "web/react/components/lead-link";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import analytics from "web/script/analytics/analytics";
import { BuyBuyBuyOptionSerializer } from "web/types/serializers";
import styles from "./other-buy-options.module.css";

export interface OtherBuyOptionsProps {
    options: BuyBuyBuyOptionSerializer[];
}

function OtherBuyOptions({ options }: OtherBuyOptionsProps): React.ReactElement {
    function onAccordionOpened({ accordionName, nonInteraction }): void {
        analytics.event("buy_area", "open_option", accordionName, nonInteraction);
    }

    function onAccordionClosed({ accordionName, nonInteraction }): void {
        analytics.event("buy_area", "close_option", accordionName, nonInteraction);
    }

    /* todo I18N-291 See ngettext for pluralization */
    const title = `From ${options.length} other store${options.length > 1 ? "s" : ""}`;

    return (
        <Accordion
            className={styles.optionsAccordion}
            name="affiliate" // TODO maybe change title to fromOtherStores, leaving for now as it affects analytics. See name implementation in Accordion
            onAccordionOpened={onAccordionOpened}
            onAccordionClosed={onAccordionClosed}
        >
            <Accordion.Title>
                <h4 className={styles.title}>{title}</h4>
            </Accordion.Title>
            <Accordion.Content>
                <div className={styles.options}>
                    {options.map((option, index) => (
                        <LeadLink
                            key={index}
                            className={styles.option}
                            rel="nofollow"
                            href={option.lead_url}
                            reason="from-other-stores"
                            openInNewTab
                        >
                            {option.image && (
                                <img className={styles.optionImage} src={option.image} />
                            )}
                            <div className={styles.optionPrice}>
                                {option.sale_price && option.sale_price !== option.price ? (
                                    <>
                                        <del>{option.price_with_currency_symbol}</del>
                                        <span className={styles.optionSalePrice}>
                                            {option.sale_price_with_currency_symbol}
                                        </span>
                                    </>
                                ) : (
                                    <span>{option.price_with_currency_symbol}</span>
                                )}
                            </div>
                            <div className={styles.optionMessage}>
                                <SVGIcon className={styles.hyperlinkIcon} name="hyperlink" />
                                <span>{`Available from ${option.retailer_name}`}</span>
                            </div>
                        </LeadLink>
                    ))}
                </div>
            </Accordion.Content>
        </Accordion>
    );
}

export default OtherBuyOptions;
