import React, { useEffect, useMemo, useRef, useState } from "react";
import { useProductCardContext } from "web/react/components/product-card/react";
import { ProductCardLink } from "web/react/components/product-card/react/product-card-link";
import analytics from "web/script/analytics/analytics";

import clsx from "clsx";
import { Conditional } from "web/react/components/conditional";
import LazyImage from "web/react/components/lazy-load-image/lazy-load-image";
import { ProductCardLabel } from "web/react/emo/product-card/product-card-label";
import { View } from "web/react/emo/view/view";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import * as styles from "./product-card-image.css";

const DEFAULT_INTERSECTION_OBSERVER_OPTIONS = {
    threshold: 0,
    rootMargin: "0px",
    once: true,
};

const today = new Date();
const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

interface ProductCardImageProps {
    src: string | null;
    backupSrc?: string | null;
    alt: string;
    lazy: boolean;
    variant?: "primary" | "secondary";
    width?: number;
    height?: number;
}

/**
 * Design System
 * @name ProductCardImage
 * @version 1.1
 * @design https://www.figma.com/file/VhOHx4tv9uYXZHXAAKPVYf/Design-System?node-id=3494%3A28275
 */
export function ProductCardImage({
    src,
    backupSrc,
    alt,
    lazy,
    variant,
    width,
    height,
}: ProductCardImageProps): JSX.Element {
    const { id, date_added: dateAdded } = useProductCardContext();
    const labelRef = useRef<HTMLDivElement>(null);
    const [isLabelVisible] = useIntersectionObserver(
        DEFAULT_INTERSECTION_OBSERVER_OPTIONS,
        labelRef
    );
    const placeholderImage = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${width} ${height}'%3E%3C/svg%3E`;
    const [currentSrc, setCurrentSrc] = useState(src);

    // Event handler to set backupSrc on error
    const switchToBackupImage = (): void => {
        if (backupSrc && currentSrc !== backupSrc) {
            setCurrentSrc(backupSrc);
        }
    };

    const imageProps = {
        width,
        height,
        alt,
        src: currentSrc || placeholderImage,
        className: styles.image,
    };

    // ENRICH-3275: Add "New arrivals" label to products added within the last 7 days
    const showNewArrivalsOnProductCards = environment.getFeature(
        "act_new_arrivals_on_product_cards"
    );
    const isNewArrival = useMemo((): boolean => {
        if (!dateAdded || !showNewArrivalsOnProductCards) {
            return false;
        }

        const date = new Date(dateAdded);

        return date >= sevenDaysAgo;
    }, [dateAdded, showNewArrivalsOnProductCards]);

    // Fire analytics when the user sees the label
    useEffect(() => {
        if (isLabelVisible) {
            analytics.event("product_card_label", "impression", "new_arrivals", true, {
                product_id: id,
            });
        }
    }, [isLabelVisible, id]);

    return (
        <View
            background="placeholder"
            padding="xxs"
            className={clsx(styles.wrapper, { [styles.secondaryWrapper]: variant === "secondary" })}
        >
            {lazy ? (
                <LazyImage
                    {...imageProps}
                    onError={switchToBackupImage}
                    placeholder={placeholderImage}
                />
            ) : // It would be nice to use the onError callback to set the src of the image to
            // backupSrc. It's more Reacty and would be consistent with the LazyImage case.
            // But it does not work...
            // The image can be loaded before hydration and therefore the event is emitted
            // and processed before the React onError callback is attached to the component.
            // Instead use this HTML trickery: the <img/> is a placeholder if we fail to
            // load the media of the <object/> (which is also an image)
            backupSrc ? (
                <object
                    data={src || placeholderImage}
                    className={styles.image}
                    style={{ aspectRatio: `auto ${width / height}` }}
                >
                    <img {...imageProps} src={backupSrc || placeholderImage} />
                </object>
            ) : (
                <img {...imageProps} src={src || placeholderImage} />
            )}
            <Conditional check={isNewArrival}>
                <ProductCardLabel
                    ref={labelRef}
                    text={gettext("product.related_products.new_arrivals")}
                />
            </Conditional>
        </View>
    );
}

interface ProductImageProps {
    shouldRenderLeadLinkInImage?: boolean;
    action?: string;
    feedType?: string;
    analyticsCategory?: string;
    analyticsEventLabel?: string;
    width?: number;
    height?: number;
}

export function ProductImage({
    feedType,
    action = "image",
    analyticsCategory = "product_card",
    analyticsEventLabel,
    shouldRenderLeadLinkInImage,
    width = 300,
    height = 375,
}: ProductImageProps): JSX.Element {
    const {
        use_lazy_images: useLazyImages,
        image_url: imageURL,
        hover_image_url: hoverImageURL,
        background_free_image_url: backgroundFreeImageUrl,
        image_alt_text: imageAltText,
        id: productId,
    } = useProductCardContext();

    // ENRICH-3312: Show second image on product cards
    // when user hovers over
    const showSecondImageOnHover = environment.getFeature("act_show_second_image_on_hover");

    function handleClick(): void {
        analytics.event(analyticsCategory, action, analyticsEventLabel, false, {
            product_id: productId,
        });
    }

    return (
        <ProductCardLink
            reason="image"
            feedType={feedType}
            onClick={handleClick}
            predicate={shouldRenderLeadLinkInImage}
        >
            <span
                className={clsx({ [styles.imagesHolder]: showSecondImageOnHover && hoverImageURL })}
            >
                <span
                    className={clsx({
                        [styles.firstImage]: showSecondImageOnHover && hoverImageURL,
                    })}
                >
                    <ProductCardImage
                        src={backgroundFreeImageUrl ? backgroundFreeImageUrl : imageURL}
                        backupSrc={backgroundFreeImageUrl ? imageURL : null}
                        alt={imageAltText}
                        lazy={useLazyImages}
                        width={width}
                        height={height}
                    />
                </span>

                <Conditional check={!!showSecondImageOnHover && !!hoverImageURL}>
                    <span className={styles.secondImage}>
                        <ProductCardImage
                            src={hoverImageURL as string}
                            alt={imageAltText}
                            lazy={useLazyImages}
                            width={width}
                            height={height}
                        />
                    </span>
                </Conditional>
            </span>
        </ProductCardLink>
    );
}
