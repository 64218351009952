import React from "react";
import { LeadLink } from "web/react/components/lead-link";
import { Text } from "web/react/emo/text/text";
import { VStack } from "web/react/emo/v-stack";
import { BuyButton } from "web/react/pages/product/in-stock/in-stock-product-page/buy-button";
import { gettext } from "web/script/modules/django-i18n";
import { ProductBuyOptionsSerializer } from "web/types/serializers";
import * as styles from "./express-checkout-disclaimer.css";

interface ExpressCheckoutDisclaimerProps {
    productId: string;
    buyOption: ProductBuyOptionsSerializer;
}

export function ExpressCheckoutDisclaimer({
    productId,
    buyOption,
}: ExpressCheckoutDisclaimerProps): JSX.Element {
    return (
        <VStack spacing="sm">
            <BuyButton
                action="buy"
                variant="affiliate"
                buyOptionPosition={0}
                buyOption={buyOption}
                variantContext={"express_checkout"}
                secondary
            />
            <Text as={"span"} className={styles.or} textStyle={"caption-1"}>
                {/* Or */}
                {gettext("in_stock_product.or_separator")}
            </Text>
            <LeadLink
                href={buyOption.affiliate_url}
                productId={productId}
                openInNewTab={true}
                reason="retailer-ec-disclaimer"
            >
                <Text as={"span"} textStyle={"callout-small"}>
                    <span className={styles.copy}>
                        {/* For the latest stock status, size availability and promotions please check directly with {} */}
                        {gettext("product.buy_area.size_stock_disclaimer", {})}
                    </span>
                    {buyOption.retailer_name}
                </Text>
            </LeadLink>
        </VStack>
    );
}
