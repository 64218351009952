import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Heading } from "web/react/emo/heading";
import * as styles from "./accordion.css";

interface AccordionItemProps {
    title: string;
    children?: React.ReactNode;
    isOpen: boolean;
    href?: string;
    onClick?: () => void;
}

function AccordionItem({
    title,
    children,
    isOpen,
    href,
    onClick,
}: AccordionItemProps): React.ReactElement {
    const [isExpanded, setIsExpanded] = useState<boolean>(isOpen);
    const [contentHeight, setContentHeight] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setContentHeight(containerRef?.current?.scrollHeight || 0);
    }, [isExpanded]);

    const titleContent = (
        <>
            <Heading as="h2" textStyle="title-3">
                {title}
            </Heading>
            <SVGIcon
                className={clsx(styles.icon, {
                    [styles.iconIsOpen]: isExpanded,
                })}
                name={"chevron-medium"}
            />
        </>
    );

    return (
        <>
            <div
                className={styles.container}
                style={assignInlineVars({
                    [styles.contentHeight]: contentHeight ? `${contentHeight}px` : "auto",
                })}
            >
                <div ref={containerRef} onClick={onClick}>
                    {href ? (
                        <a href={href} className={styles.title}>
                            {titleContent}
                        </a>
                    ) : (
                        <div
                            className={styles.title}
                            onClick={() => {
                                setIsExpanded(!isExpanded);
                            }}
                        >
                            {titleContent}
                        </div>
                    )}
                    {children && (
                        <div
                            className={clsx(styles.content, { hidden: !isExpanded })}
                            data-testid="accordion-item-content"
                        >
                            {children}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.divider} />
        </>
    );
}

export interface AccordionProps {
    items: AccordionItemProps[];
}

export function Accordion({ items }: AccordionProps): React.ReactElement {
    return (
        <>
            <div className={styles.divider} />
            {items.map((props, index) => (
                <AccordionItem key={`${props.title}-${index}`} {...props}>
                    {props.children}
                </AccordionItem>
            ))}
        </>
    );
}
