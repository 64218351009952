import _each from "lodash/each";
import environment from "web/script/modules/environment";
import userProfiler from "web/script/modules/userprofiler";

export default {
    brazeApiKey: environment.get("brazeApiKey"),
    brazeSdk: null,

    init: async function () {
        if (this.brazeApiKey && userProfiler.isLoggedIn()) {
            try {
                this.brazeSDKPromise = import("@braze/web-sdk");

                const { default: braze } = await this.brazeSDKPromise;
                this.brazeSdk = braze;
                this.brazeSdk.initialize(this.brazeApiKey, {
                    baseUrl: "sdk.fra-01.braze.eu",
                });
                this.brazeSdk.changeUser(environment.get("userId"));
                this.brazeSdk.openSession();
            } catch (err) {
                console.error(err);
            }
        }
    },

    send: async function (data) {
        if (userProfiler.isLoggedIn()) {
            await this.brazeSDKPromise;
            _each(data, (event) => {
                if (this.brazeSdk != null) {
                    if (
                        event.data.category === "save_for_later" &&
                        event.data.action === "add_item"
                    ) {
                        this.brazeSdk.logCustomEvent("clicked save product");
                    } else if (event.type === "page_view" && event.data.product_id) {
                        this.brazeSdk.logCustomEvent("clicked product");
                    }
                }
            });
        }
        return Promise.resolve();
    },
};
