import React from "react";

import { StoryDetails } from "web/react/emo/story-details";
import { useStoryAnalytics } from "web/react/hooks/use-story-analytics";
import { gettext } from "web/script/modules/django-i18n";
import { HeroStorySerializer } from "web/types/serializers";

import * as styles from "./hero-story.css";

interface HeroStoryProps extends HeroStorySerializer {
    position: number;
    alt?: string;
}

export function HeroStory({
    id: componentId,
    title,
    caption,
    url,
    component_label: componentLabel,
    desktop_image_url: desktopImage,
    mobile_image_url: mobileImage,
    is_sponsored: isSponsored,
    position,
    alt,
}: HeroStoryProps): React.ReactElement {
    const { containerRef, sendClickEvent } = useStoryAnalytics({
        label: "hero_story",
        componentId,
        componentLabel,
        position,
        isSponsored,
    });

    return (
        <section ref={containerRef} className={styles.wrapper} data-testid={"hero-story"}>
            <a
                data-testid="hero-story-image"
                href={url}
                className={styles.link}
                rel="noreferrer"
                onClick={() => sendClickEvent("image")}
            >
                <picture className={styles.picture}>
                    <source
                        data-testid="mobile-image-source"
                        media={styles.mobileMedia}
                        srcSet={mobileImage}
                    />
                    <source
                        data-testid="desktop-image-source"
                        media={styles.desktopMedia}
                        srcSet={desktopImage}
                    />
                    <img
                        data-test-id="image"
                        src={mobileImage}
                        className={styles.image}
                        alt={alt}
                    />
                </picture>
            </a>
            <div data-testid="hero-story" className={styles.editorialDetails}>
                <StoryDetails
                    customButtonText={gettext("general.explore_now")}
                    details={{
                        isSponsored,
                        title,
                        caption,
                        url,
                    }}
                    onCtaClick={() => sendClickEvent("cta")}
                    captionLineLimit={10}
                    titleLineLimit={6}
                />
            </div>
        </section>
    );
}
