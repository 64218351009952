import clsx from "clsx";
import React from "react";
import { KevelCampaignControl } from "web/react/components/kevel-campaign-control/kevel-campaign-control";
import { useLeadLinkClick } from "web/react/hooks/use-lead-link-click/use-lead-link-click";
import { useProductClick } from "web/react/hooks/use-product-click/use-product-click";
import withRedux from "web/react/redux-provider";
import { gettextNoop } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import storage from "web/script/utils/storage";
import { useProductCardContext } from "./product-card";
import * as styles from "./product-card-link.css";

function getAnalyticsEventLabel(contextType, reason): string {
    const suffix = reason === "text" ? "_desc" : "";

    const analyticsEventLabels = {
        // maps analytics labels used in web to app campaigns to the context type
        "feed-product": `product_card_block${suffix}`,
        "related-product": `related_product_card${suffix}`,
        "search-product": `product_card_block${suffix}`,
    };

    return analyticsEventLabels[contextType];
}

interface ProductCardLinkProps {
    reason: string;
    predicate?: boolean;
    className?: string;
    children?: any;
    feedType?: string;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

function _ProductCardLink({
    children,
    reason,
    className,
    predicate,
    onClick,
    onMouseEnter,
    onMouseLeave,
    feedType,
}: ProductCardLinkProps): React.ReactElement {
    const {
        id,
        uid,
        url,
        slug,
        is_affiliate: isAffiliate,
        lead_enabled: leadEnabled,
        image_url: imageURL,
        in_stock: inStock,
        link_id: linkId,
        lead_url: leadURL,
        affiliate_promo_url: affiliatePromoURL,
        context_type: contextType,
        overlay_enabled: overlayEnabled,
        excluded_from_app: retailerExcludedFromApp,
        app_deeplink_path: appDeeplinkPath,
        icon_enabled: checkoutEnabled,
        retailer_slug: retailerSlug,
        currency,
        hash_url: hashURL,
        disable_overlay_on_image_click: disableOverlayOnImageClick,
    } = useProductCardContext();

    // Calling here to work around baustein-related issue with gettext parsing.
    // Should be fixed when overlay is brought to react in redesign work.
    // https://jira.lystit.com/browse/CW-598
    // https://jira.lystit.com/browse/CW-1070
    gettextNoop("in_stock_product.store_comparison_title");
    gettextNoop("in_stock_product.compare_prices_text_link");
    gettextNoop("is_product.shop_now.app_overlay_block.title_1");
    gettextNoop("is_product.shop_now.app_overlay_block.title_3");
    gettextNoop("is_product.shop_now.app_overlay_block.title_4");

    const contextualisedReason = `${contextType}-${reason}`;

    // Remove leads on Feeds for OOS PDPs
    const pageType = environment.get("pageType");
    const isFeedPage = pageType === "feed";
    const removeLeadsOnFeedsOOSItems = isFeedPage && !inStock;

    const shouldRenderLeadLink =
        (!checkoutEnabled &&
            isAffiliate &&
            leadEnabled &&
            !removeLeadsOnFeedsOOSItems &&
            predicate) ||
        contextType === "collection_products";
    const obfuscateLeadLink = environment.getFeature("seo_obfuscate_lead_link");

    const { onClick: onProductClick } = useProductClick({
        id: id.toString(),
        productUrl: url,
        productImageUrl: imageURL || undefined,
        inStock,
        shouldRedirect: false,
        overlayEnabled: reason === "image" && disableOverlayOnImageClick ? false : overlayEnabled,
        productUid: uid,
        productSlug: slug,
        reason: contextualisedReason,
        linkId: linkId?.toString(),
        href: url,
        isLeadLink: shouldRenderLeadLink,
        openInNewTab: shouldRenderLeadLink,
        contextType,
        feedType,
        appDeeplinkPath,
        checkoutEnabled,
        hashURL,
    });

    function beforeLeadLinkClick(callback, event): void {
        onProductClick(event);
        callback(event);
    }

    const { onClick: onLeadLinkClick, leadHref } = useLeadLinkClick({
        href: leadURL as string,
        productId: id.toString(),
        openInNewTab: true,
        affiliatePromoUrl: affiliatePromoURL || undefined,
        reason: contextualisedReason,
        beforeOnClick: beforeLeadLinkClick,
    });

    function handleClick(event): void {
        onProductClick(event);
    }

    function beforeCampaignOnClick(): void {
        // send analytics data before making a campaign decision
        onClick?.();
    }

    function onCampaignNotActive(event): void {
        if (shouldRenderLeadLink) {
            onLeadLinkClick(event);
        } else {
            handleClick(event);
        }
    }

    function getUrlWithLinkId(): string {
        let newUrl = url;
        if (newUrl.indexOf("link_id") === -1 && linkId) {
            if (newUrl.indexOf("?") === -1) {
                newUrl += `?link_id=${linkId}`;
            } else {
                newUrl += `&link_id=${linkId}`;
            }
        }
        return newUrl;
    }

    function renderLeadLink(): React.ReactElement {
        if (obfuscateLeadLink) {
            return (
                <span
                    className={clsx(styles.span, className)}
                    role="button"
                    tabIndex={0}
                    onClick={() => window.open(leadHref, "_blank")}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            window.open(leadHref, "_blank");
                        }
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    dangerouslySetInnerHTML={
                        typeof children === "string" ? { __html: children } : undefined
                    }
                >
                    {typeof children !== "string" ? children : null}
                </span>
            );
        }

        return (
            <a
                href={leadHref}
                className={className}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                target={"_blank"}
                rel="nofollow noreferrer"
                dangerouslySetInnerHTML={
                    typeof children === "string" ? { __html: children } : undefined
                }
            >
                {typeof children !== "string" ? children : null}
            </a>
        );
    }

    return (
        <KevelCampaignControl
            contextType={contextType || "product_card"}
            options={{
                isIcon: checkoutEnabled,
                action: "product_card_click",
                areaClicked: reason,
                newUserGenerated: storage.get("new_user_to_sign_up", null, true) === "complete",
                retailerExcludedFromApp: retailerExcludedFromApp,
                heimdallFlags: [
                    "eac_desktop_product_card_signup_block",
                    "eac_show_is_cheapest_possible_price_on_feed_modal",
                ],
                productId: id,
                linkId,
                currency,
                retailerSlug,
            }}
            beforeOnClick={beforeCampaignOnClick}
            defaultAction={onCampaignNotActive}
            analyticsEventLabel={getAnalyticsEventLabel(contextType || "", reason)}
            productImageUrl={imageURL || ""}
            continueOptionHref={hashURL || getUrlWithLinkId()}
            appDeepLinkPath={appDeeplinkPath}
            productUrl={url}
        >
            {!shouldRenderLeadLink && (
                <a
                    href={hashURL || url}
                    className={className}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    {children}
                </a>
            )}

            {shouldRenderLeadLink && renderLeadLink()}
        </KevelCampaignControl>
    );
}

export const ProductCardLink = withRedux(_ProductCardLink);
