import React from "react";
import LazyImage from "web/react/components/lazy-load-image/lazy-load-image";
import { StoryDetails } from "web/react/emo/story-details";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { StoryCardSerializer } from "web/types/serializers";
import * as styles from "./story-card.css";

interface StoryCardProps extends StoryCardSerializer {
    lazy?: boolean;
    alt?: string;
    sendClickEvent: (action: string) => void;
    customButtonText?: string;
}

export function StoryCard({
    title,
    caption,
    url,
    image_url: imageSrc,
    is_sponsored: isSponsored,
    lazy = false,
    alt,
    sendClickEvent,
    customButtonText,
}: StoryCardProps): React.ReactElement {
    const { isMobileViewport } = useDomViewport();

    return (
        <div className={styles.storyCard}>
            {lazy ? (
                <a href={url} onClick={() => sendClickEvent("image")} data-testid={"image"}>
                    <LazyImage src={imageSrc} className={styles.image} alt={alt} />
                </a>
            ) : (
                <a href={url} onClick={() => sendClickEvent("image")} data-testid={"image"}>
                    <img className={styles.image} src={imageSrc} alt={alt} />
                </a>
            )}
            <View width="full">
                <StoryDetails
                    isBodyClickable={isMobileViewport}
                    variant="compact"
                    customButtonText={customButtonText}
                    details={{
                        isSponsored,
                        title,
                        caption,
                        url,
                    }}
                    onCtaClick={() => sendClickEvent("cta")}
                />
            </View>
        </div>
    );
}
