import { languageIsIn } from "web/script/modules/language";
import browser from "web/script/utils/browser";
import environment from "./environment";

const MEMBERSHIP_LANGUAGES = ["en", "de", "fr", "es", "it", "nl"];
/**
 * Utility function to be able to tell whether membership
 * functionalities like signing up/in, wishlisting, etc
 * can be displayed / used
 */
export function canUseMembership(): boolean {
    return languageIsIn(MEMBERSHIP_LANGUAGES);
}

export function shouldShowOmnibusPages(): boolean {
    return !!environment.get("showOmnibusPages");
}

export function isMobile(): boolean {
    return browser.getViewport() === browser.VIEWPORTS.MOBILE;
}

export function isTablet(): boolean {
    return browser.getViewport() === browser.VIEWPORTS.TABLET;
}

export function isDesktop(): boolean {
    return browser.getViewport() === browser.VIEWPORTS.DESKTOP;
}
