import pinterest from "web/script/embedded/pinterest";

export default {
    send: function (data) {
        data.forEach((event) => {
            switch (event.type) {
                case "page_view":
                    // Send Page View events on all pages apart from Lead page
                    if (event.data.page_type !== "lead") {
                        pinterest("track", "pagevisit");
                    }
                    break;

                case "event":
                    // Send Add to Cart events to pinterest
                    const category = event.data.category;
                    const action = event.data.action;
                    const label = event.data.label;
                    if (
                        category === "cart" &&
                        action === "add_item" &&
                        label == "add_to_cart_button"
                    ) {
                        // These tag events will be linked to the
                        // Pinterest catalogues
                        pinterest("track", "addtocart", {
                            value: event.data.customData.price,
                            order_quantity: 1,
                            currency: "USD",
                            line_items: [
                                {
                                    product_id: event.data.customData.product_id,
                                    product_category: "product",
                                },
                            ],
                        });
                    } else if (
                        category === "buy_area" &&
                        action === "clicked" &&
                        label == "buy-on-store-button"
                    ) {
                        // These tag events will not be linked to the
                        // Pinterest catalogues
                        pinterest("track", "addtocart", {
                            order_quantity: 1,
                            line_items: [
                                {
                                    product_category: "product",
                                },
                            ],
                        });
                    }
                    break;

                default:
                    break;
            }
        });

        return Promise.resolve();
    },
};
