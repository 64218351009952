import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MemoryRouter, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CloseButton from "web/react/components/buttons/close-button/close-button";
import LazyModal from "web/react/components/lazy-modal/lazy-modal";
import SoftSignupForm from "web/react/components/soft-signup-dialog/soft-signup-form";
import { ValuePropositionAnimation } from "web/react/components/value-proposition-animation";
import { HStack } from "web/react/emo/h-stack";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { ForgotPasswordEmailSentScreen } from "web/react/pages/account/signup-or-login-page/forgot-password-email-sent-screen";
import { ForgotPasswordScreen } from "web/react/pages/account/signup-or-login-page/forgot-password-screen";
import { LoginEmailSentScreen } from "web/react/pages/account/signup-or-login-page/login-email-sent-screen";
import { LoginScreen } from "web/react/pages/account/signup-or-login-page/login-screen";
import { CaptureType, closeCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import * as styles from "./soft-signup-dialog.css";

interface SoftSignupRoutesProps {
    showSocialAuth: boolean;
    legalTextKey: string;
    showMarketingCheckbox: boolean;
}

function SoftSignupRoutes({
    showSocialAuth,
    legalTextKey,
    showMarketingCheckbox,
}: SoftSignupRoutesProps): JSX.Element {
    const [email, setEmail] = useState("");
    const location = useLocation();
    const { isMobileViewport, isTabletViewport, isDesktopViewport } = useDomViewport();

    return (
        <TransitionGroup className={styles.flexRow}>
            <CSSTransition
                key={location.key}
                classNames={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    exit: styles.exit,
                    exitActive: styles.exitActive,
                }}
                timeout={{ enter: 1000, exit: 500 }}
                mountOnEnter
                unmountOnExit
            >
                <View
                    className={styles.form}
                    marginTop={location.pathname === "/" ? {} : { lg: "xl" }}
                    padding={{ sm: "sm", lg: "lg" }}
                    paddingTop={{ sm: isMobileViewport ? "none" : "sm", md: "xl" }}
                    paddingBottom={{ sm: "sm", md: "xl" }}
                >
                    <Routes location={location}>
                        <Route
                            index
                            element={
                                <SoftSignupForm
                                    email={email}
                                    setEmail={setEmail}
                                    showSocialAuth={showSocialAuth}
                                    legalTextKey={legalTextKey}
                                    showMarketingCheckbox={showMarketingCheckbox}
                                />
                            }
                        />
                        {(isTabletViewport || isDesktopViewport) && (
                            <>
                                <Route
                                    path="/login"
                                    element={
                                        <LoginScreen email={email} headingStyle={"large-title-2"} />
                                    }
                                />
                                <Route
                                    path="/login/email-sent"
                                    element={
                                        <LoginEmailSentScreen
                                            email={email}
                                            headingStyle={"large-title-2"}
                                        />
                                    }
                                />
                                <Route
                                    path="/forgot-password"
                                    element={
                                        <ForgotPasswordScreen
                                            onSuccess={setEmail}
                                            email={email}
                                            headingStyle={"large-title-2"}
                                        />
                                    }
                                />
                                <Route
                                    path="/forgot-password/email-sent"
                                    element={
                                        <ForgotPasswordEmailSentScreen
                                            email={email}
                                            headingStyle={"large-title-2"}
                                        />
                                    }
                                />
                            </>
                        )}
                    </Routes>
                </View>
            </CSSTransition>
        </TransitionGroup>
    );
}

interface ModalImageProps {
    imageSrc?: string;
}

const ModalImage = ({ imageSrc }: ModalImageProps): JSX.Element => {
    return imageSrc ? (
        <div className={styles.imageWrapper}>
            <img className={styles.modalImage} src={imageSrc} />
        </div>
    ) : (
        <View width="full">
            <ValuePropositionAnimation className={styles.modalAnimation} />
        </View>
    );
};

interface SoftSignupDialogProps {
    captureType: CaptureType;
    overlayOpen: boolean;
    blockType?: string;
    analyticsEventLabel?: string;
    analyticsEventCategory?: string;
    imageSrc?: string;
    showSocialAuth?: boolean;
    legalTextKey?: string;
    showMarketingCheckbox?: boolean;
}

export const SoftSignupDialog = ({
    captureType,
    blockType,
    analyticsEventCategory,
    analyticsEventLabel,
    overlayOpen,
    imageSrc,
    showSocialAuth = true,
    legalTextKey = "",
    showMarketingCheckbox = true,
}: SoftSignupDialogProps): JSX.Element => {
    const dispatch = useDispatch();
    const truncate = analyticsEventCategory !== "aspirer_acquisition_campaign";
    const { isTabletViewport, isDesktopViewport } = useDomViewport();
    const analyticsCategoryPrefix = isDesktopViewport ? "desktop_signup" : "mobile_signup";

    const analyticsCategory = analyticsEventCategory
        ? `${analyticsCategoryPrefix}_${analyticsEventCategory}`
        : analyticsCategoryPrefix;

    function onAfterOpen(): void {
        analytics.event(
            captureType,
            "open_signup_form",
            "open_overlay",
            false,
            {},
            "",
            {},
            [],
            false,
            truncate
        );
        analytics.event(
            analyticsCategory,
            "shown",
            analyticsEventLabel,
            false,
            {},
            "",
            {},
            [],
            false,
            truncate
        );
    }

    function onClose(): void {
        analytics.event(
            captureType,
            "close_signup_form",
            "exit_signup",
            false,
            {},
            "",
            {},
            [],
            false,
            truncate
        );
        analytics.event(
            analyticsCategory,
            "closed",
            analyticsEventLabel,
            false,
            {},
            "",
            {},
            [],
            false,
            truncate
        );
        dispatch(closeCustomerCaptureOverlay());
    }

    const appElement = document.body?.querySelector<HTMLDivElement>(".site-wrapper");

    const closable = blockType !== "hard_block_after_action";

    return (
        <LazyModal
            appElement={appElement || undefined}
            className={styles.modal}
            isOpen={overlayOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onClose}
            overlayClassName={styles.overlay}
            shouldCloseOnOverlayClick={closable}
            shouldCloseOnEsc={closable}
            noScroll={false}
        >
            {closable && (
                <div className={styles.closeContainer}>
                    <CloseButton
                        useDefaultStyle={false}
                        className={styles.closeButton}
                        onClick={onClose}
                    />
                </div>
            )}
            <div data-testid="signup-overlay">
                <HStack align="stretch">
                    <View width="full">
                        <MemoryRouter>
                            <SoftSignupRoutes
                                showSocialAuth={showSocialAuth}
                                legalTextKey={legalTextKey}
                                showMarketingCheckbox={showMarketingCheckbox}
                            />
                        </MemoryRouter>
                    </View>
                    {(isTabletViewport || isDesktopViewport) && <ModalImage imageSrc={imageSrc} />}
                </HStack>
            </div>
        </LazyModal>
    );
};
