import clsx from "clsx";
import React from "react";

import { Conditional } from "web/react/components/conditional";
import { LeadLink } from "web/react/components/lead-link";
import { Promo } from "web/react/components/promo-offer/pdp-promo";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack";
import { ListItem } from "web/react/emo/list-item/list-item";
import { Pill } from "web/react/emo/pill";
import { Retailer } from "web/react/emo/retailer";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import {
    BuyOptionPromotionSerializer,
    ShippingInformation,
} from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./pdp-pricing-card.css";

export interface PricingCardPDPProps {
    retailerName: string;
    sizeList?: string[];
    shippingInformation?: ShippingInformation[];
    leadUrl: string;
    isSizeSelected: boolean;
    promotion: BuyOptionPromotionSerializer | null;
}

export function PricingCardPDP({
    retailerName,
    sizeList = [],
    shippingInformation,
    leadUrl,
    isSizeSelected,
    promotion,
}: PricingCardPDPProps): JSX.Element {
    const pillList = sizeList.slice(0, 3);

    return (
        <View border="keyline" borderColor="grayscale300" className={styles.wrapper}>
            <VStack spacing="xxs">
                <Text textStyle={"callout"} color={"secondary"}>
                    <LeadLink
                        href={leadUrl}
                        rel="noreferrer"
                        className={styles.link}
                        reason="retailer-link"
                        openInNewTab
                    >
                        <Retailer retailerName={retailerName} fromSpanStyle={"capitalize"} />
                    </LeadLink>
                </Text>
            </VStack>
            {/* TODO Reviews from trust pilot*/}
            {/* CW-1191 Display size preview component when we display don't see your size button */}
            <Conditional check={!!pillList}>
                <View className={clsx(styles.pillList, styles.hidden)}>
                    {/* Hide size pills when size is selected from the size picker or only one size is available */}
                    {!isSizeSelected && pillList.length > 0 && (
                        <HStack spacing="xxs" wrap>
                            {pillList?.map((size) => (
                                <Pill key={size}>{size}</Pill>
                            ))}
                            <Conditional check={sizeList.length > 3}>
                                <Pill>
                                    {/* "+ {count} More" */}
                                    {gettext("product.buy_area.sizes_more_count.label", {
                                        count: sizeList.length - 3,
                                    })}
                                </Pill>
                            </Conditional>
                        </HStack>
                    )}
                </View>
            </Conditional>
            <Conditional check={!!shippingInformation}>
                <ul className={styles.shippingInfoWrapper}>
                    {shippingInformation?.map((shippingInfo, index) => (
                        <ListItem
                            key={retailerName + index}
                            variant={"primary"}
                            size="xs"
                            flexWrapperHeight
                            align="start"
                        >
                            <SVGIcon
                                name={shippingInfo.isFree ? "tick" : "plus"}
                                className={styles.tickIcon}
                                overrideStyle
                            />
                            <Text
                                textStyle={"body-2"}
                                color={"secondary"}
                                className={styles.shippingInfo}
                                as="span"
                            >
                                {shippingInfo.info}
                            </Text>
                        </ListItem>
                    ))}
                </ul>
            </Conditional>
            {!!promotion && (
                <div className={styles.promotion}>
                    <Promo promotion={promotion} />
                </div>
            )}
        </View>
    );
}
