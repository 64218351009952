import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import editButtonTemplate from "templates/modules/product/product_edit_button.jinja";
// Styles
// eslint-disable-next-line css-modules/no-unused-class
import buttonStyles from "web/react/components/common/styles/button-styles.module.css";
// Components
import Conditional from "web/react/components/conditional/conditional";
import { LeadLink } from "web/react/components/lead-link";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
// This should be migrated to React
import Template from "web/react/components/template/template";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { languageIsEnglish } from "web/script/modules/language";

import { OOSProductHeroSerializer, ProductImageSerializer } from "web/types/serializers";
import styles from "./oos-product-hero.module.css";

interface OOSProductHeroImageProps {
    image: ProductImageSerializer;
}

function OOSProductHeroImage({ image }: OOSProductHeroImageProps): JSX.Element {
    return (
        <img
            src={image.large_url}
            srcSet={`${image.large_url} 1x${
                image.large_url_2x ? ", " + image.large_url_2x + " 2x" : ""
            }`}
            alt={image.alt_text}
            width={200}
            height={250}
        />
    );
}

export interface OOSProductHeroProps {
    data: OOSProductHeroSerializer;
    variant: "icon" | "affiliate";
    productUrl?: string;
}

function OOSProductHero({ data, variant }: OOSProductHeroProps): React.ReactElement {
    const {
        product_header: productHeader,
        oos_product_header: oosProductHeader,
        product_edit_button: productEditButton,
        product_image_gallery: productImageGallery,
    } = data;

    const { lead_push_link: leadPushLink, title, body_html: bodyHTML } = data;
    const isAffiliate = variant === "affiliate";
    const isIcon = variant === "icon";

    const dispatch = useDispatch();

    const hasImages =
        !!productImageGallery &&
        !!productImageGallery.images &&
        !!productImageGallery.images.length;

    const firstImage = hasImages ? productImageGallery?.images[0] : null;

    function openCustomerCapturePrompt(): void {
        if (!languageIsEnglish() || oosProductHeader.is_saved) {
            return;
        }

        dispatch(
            openCustomerCaptureOverlay({
                productId: productHeader.product_id,
                captureType: "stock_alert",
            })
        );
    }

    function handleLeadLinkClick(): void {
        if (!languageIsEnglish()) {
            return;
        }

        dispatch(
            openCustomerCaptureOverlay({
                productId: productHeader.product_id,
                captureType: "stock_alert_lead_return",
            })
        );
    }

    return (
        <article className={styles.hero}>
            <Conditional check={productEditButton !== null} key="edit-button">
                <Template
                    tag="div"
                    className="hidden-mobile"
                    template={editButtonTemplate}
                    args={{
                        edit_button: productEditButton,
                    }}
                />
            </Conditional>
            <div className={styles.section}>
                <div className={styles.info}>
                    <Conditional key="hero-image" check={hasImages}>
                        <div className={styles.image}>
                            {isAffiliate && leadPushLink ? (
                                <LeadLink
                                    href={leadPushLink}
                                    rel="nofollow"
                                    reason="affiliate-gallery-image"
                                    openInNewTab={true}
                                >
                                    <OOSProductHeroImage
                                        image={firstImage as ProductImageSerializer}
                                    />
                                </LeadLink>
                            ) : (
                                <OOSProductHeroImage image={firstImage as ProductImageSerializer} />
                            )}
                        </div>
                    </Conditional>

                    <div>
                        <h1 className={styles.heading}>
                            <span className={styles.title}>
                                {productHeader.is_owner_active &&
                                productHeader.is_owner_feed_linkable ? (
                                    <a
                                        className={styles.designer}
                                        href={productHeader.designer_url}
                                    >
                                        {productHeader.owner_name + " "}
                                    </a>
                                ) : (
                                    productHeader.owner_name
                                )}
                            </span>

                            {!!productHeader.short_description && (
                                <span
                                    className={styles.shortDescription}
                                    data-testid="short-description"
                                >
                                    {productHeader.short_description}
                                </span>
                            )}
                        </h1>

                        <Conditional
                            key="feed-links"
                            check={
                                !!oosProductHeader.last_seen_for_price_at_retailer_link_html ||
                                !!oosProductHeader.breadcrumb_link_html
                            }
                        >
                            <div className={styles.feedLinks}>
                                {isIcon &&
                                    !!oosProductHeader.last_seen_for_price_at_retailer_link_html && (
                                        <p
                                            className="m0 mt10"
                                            dangerouslySetInnerHTML={{
                                                __html: oosProductHeader.last_seen_for_price_at_retailer_link_html, // Last seen for <em><span {currency_symbol_span_props}>{currency_symbol}</span><span {price_span_props}>{price}</span></em> at <a {retailer_link_props}>{retailer_name}</a>.
                                            }}
                                        />
                                    )}
                                {!!oosProductHeader.breadcrumb_link_html && (
                                    <p
                                        className="m0 mt5 hidden-mobile"
                                        dangerouslySetInnerHTML={{
                                            __html: oosProductHeader.breadcrumb_link_html, // See more <a {breadcrumb_link_props}>{breadcrumb_link_text}</a>.
                                        }}
                                    />
                                )}
                            </div>
                        </Conditional>
                    </div>
                </div>
            </div>

            <div className={styles.section}>
                <Conditional check={isIcon} key="icon-email-capture">
                    <div>
                        <h3 className="mb10 hidden-mobile">
                            {
                                !oosProductHeader.is_saved
                                    ? gettext("oos_product.create_back_in_stock_alert_title") // Get notified when it's back...
                                    : gettext("oos_product.back_in_stock_alert_is_active_title") // You'll be notified when it's back...
                            }
                        </h3>
                        <p className="oos-product-hero__alert-body mt0">
                            {
                                gettext("oos_product.create_back_in_stock_alert_body")
                                /* We check over 450 retailers daily. Be the first to know when it comes back in stock. */
                            }
                        </p>

                        <button
                            disabled={oosProductHeader.is_saved}
                            className={clsx(buttonStyles.button, styles.button, styles.iconButton, {
                                [buttonStyles.cta]: !oosProductHeader.is_saved,
                                [buttonStyles.disabledButton]: oosProductHeader.is_saved,
                            })}
                            onClick={openCustomerCapturePrompt}
                        >
                            {gettext("oos_product.create_back_in_stock_alert_btn_text")}
                            {/* Alert me */}
                        </button>
                    </div>
                </Conditional>
                <Conditional check={isAffiliate} key="affiliate-lead">
                    <>
                        {title && <h3 className="mb10 hidden-mobile">{title}</h3>}

                        {bodyHTML && (
                            <p className="mt0" dangerouslySetInnerHTML={{ __html: bodyHTML }} />
                        )}

                        {leadPushLink && (
                            <>
                                <LeadLink
                                    href={leadPushLink}
                                    rel="nofollow"
                                    openInNewTab={true}
                                    reason="affiliate-buy-on-store-button"
                                    className={clsx(
                                        buttonStyles.button,
                                        buttonStyles.primaryButton,
                                        styles.button
                                    )}
                                    beforeOnClick={(process, event) => {
                                        analytics.event(
                                            "oos-product-hero-button",
                                            "click",
                                            "affiliate-buy-on-store-button"
                                        );
                                        process(event);
                                    }}
                                    afterOnClick={handleLeadLinkClick}
                                >
                                    <SVGIcon name="hyperlink" />
                                    <span data-testid="affiliate-lead">
                                        {
                                            oosProductHeader.last_seen_for_price_at_retailer_link_btn_text
                                        }
                                    </span>
                                </LeadLink>
                            </>
                        )}
                    </>
                </Conditional>
            </div>
        </article>
    );
}

export default OOSProductHero;
