import _escape from "lodash/escape";
import PropTypes from "prop-types";
import React from "react";
import { LeadLink } from "web/react/components/lead-link";
import { gettext } from "web/script/modules/django-i18n";
import { getHtmlProps } from "web/script/modules/html-tag-props";
import styles from "./retailer-link.module.css";

function RetailerLink({ retailerName, leadUrl }) {
    const fromSpanProps = {
        class: styles.from,
    };

    // we escape retailerName, and so mark this string as available for dangerouslySetInnerHTML

    const fromRetailerLabelSafeHtml = {
        __html: gettext("in_stock_product.from_retailer.in_spans", {
            from_span_props: getHtmlProps(fromSpanProps),
            retailer_name_span_props: "",
            retailer_name: _escape(retailerName),
        }),
    };

    return (
        <LeadLink reason="retailer-link" href={leadUrl}>
            <span dangerouslySetInnerHTML={fromRetailerLabelSafeHtml} />
        </LeadLink>
    );
}

export default RetailerLink;

RetailerLink.propTypes = {
    retailerName: PropTypes.string.isRequired,
    leadUrl: PropTypes.string.isRequired,
    className: PropTypes.string,
};

RetailerLink.defaultProps = {
    type: "default",
};
