import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { BREAKPOINTS } from "web/react/constants";
import { useClientWidth } from "web/react/hooks/use-client-width/use-client-width";
import { useIntersectionObserver } from "web/react/hooks/use-intersection-observer/use-intersection-observer";
import withReduxProvider from "web/react/redux-provider";
import analytics from "web/script/analytics/analytics";
import googleCsa from "web/script/embedded/google-adsense";
import globals from "web/script/modules/globals";
import { getLanguageWithoutCountry } from "web/script/modules/language";
import onAdLoaded, { trackKevelEvent } from "web/script/utils/ad-analytics";
import { AdsenseForShoppingSerializer } from "web/types/serializers";
import styles from "./adsense-shopping.module.css";

const DEFAULT_INTERSECTION_OBSERVER_OPTIONS = {
    rootMargin: "0px",
    once: true,
};

export function getFromDeviceMap(
    pagetype: string,
    windowWidth: number
): { styleId: string; numberOfProducts: number } {
    const shoppingUnitMap = {
        feed: {
            mobile: { styleId: "2982273677", numberOfProducts: 11 },
            smalltablet: { styleId: "6481503537", numberOfProducts: 3 },
            tablet: { styleId: "8178701801", numberOfProducts: 3 },
            bigtablet: { styleId: "9738297594", numberOfProducts: 3 },
            netbook: { styleId: "2845634925", numberOfProducts: 4 },
            desktop: { styleId: "5471799531", numberOfProducts: 4 },
        },
        pdp: {
            mobile: { styleId: "9300207983", numberOfProducts: 11 },
            smalltablet: { styleId: "7112132988", numberOfProducts: 3 },
            tablet: { styleId: "7987125680", numberOfProducts: 4 },
            desktop: { styleId: "4485968382", numberOfProducts: 5 },
        },
        overlay: {
            // Overlay usually only seen on desktop
            mobile: { styleId: "8043026768", numberOfProducts: 2 },
            tablet: { styleId: "8043026768", numberOfProducts: 3 },
            desktop: { styleId: "8043026768", numberOfProducts: 5 },
        },
    };
    const deviceMap = shoppingUnitMap[pagetype];
    // NB. not BREAKPOINTS.ExtraLarge, a bit bigger than that (1200px)
    if (windowWidth >= 1305) {
        // optional desktop breakpoint
        return deviceMap["desktop"];
        // NB. not BREAKPOINTS.Large, a tiny bit bigger (992px)
    } else if (windowWidth >= 1021) {
        // optional netbook breakpoint - defaults to desktop
        if ("netbook" in deviceMap) {
            return deviceMap["netbook"];
        }
        return deviceMap["desktop"];
    } else if (windowWidth >= BREAKPOINTS.Large) {
        // optional breakpoint - defaults to desktop
        if ("bigtablet" in deviceMap) {
            return deviceMap["bigtablet"];
        }
        if ("tablet" in deviceMap) {
            return deviceMap["tablet"];
        }
        return deviceMap["desktop"];
    } else if (windowWidth >= BREAKPOINTS.Medium) {
        // optional tablet breakpoint - defaults to desktop
        if ("bigtablet" in deviceMap) {
            return deviceMap["bigtablet"];
        }
        if ("tablet" in deviceMap) {
            return deviceMap["tablet"];
        }
        return deviceMap["desktop"];
    } else if (windowWidth >= BREAKPOINTS.Small) {
        // optional tablet breakpoint - defaults to mobile
        if ("smalltablet" in deviceMap) {
            return deviceMap["smalltablet"];
        }
    }
    return deviceMap["mobile"];
}

function isPreviewDomain(): boolean {
    if (globals.window.hasOwnProperty("location")) {
        // Only previews (or dev test traffic) directly use cluster-specific URLs
        return globals.window.location.hostname.includes(".srv.lystit.com");
    }
    return false;
}

interface AdsenseShoppingProps extends AdsenseForShoppingSerializer {
    onOverlay?: boolean;
}

function AdsenseShopping({
    keyword,
    custom_channel_id: channelId,
    style_id: lystStyleId,
    click_url: clickUrl,
    impression_url: impressionUrl,
    personalized_ads: personalizedAdsDirty,
}: AdsenseShoppingProps): JSX.Element {
    const { styleId: googleStyleId, numberOfProducts } = getFromDeviceMap(
        lystStyleId,
        useClientWidth()
    );

    // container attributes and states
    const divId = `adsense-custom-search-ads-${lystStyleId}`;
    const divRef = useRef<HTMLDivElement>(null);
    const [isVisible] = useIntersectionObserver(
        DEFAULT_INTERSECTION_OBSERVER_OPTIONS,
        divRef as any
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasAd, setHasAd] = useState(false);

    // cookie consent
    // wanted to use state.cookieConsentReducer.acceptsAds
    // probably via useSelector
    // but has some big problems due to renderReactCompomentInDocument
    // so we read the cookie on the server side and pass as a prop
    // as final insurance, don't accidentally send non-booleans to analytics customData
    const personalizedAds = personalizedAdsDirty ? true : false;

    useEffect((): void => {
        const pageOptions = {
            pubId: "partner-pub-7193719621719731",
            styleId: googleStyleId,
            query: keyword,
            channel: channelId,
            hl: getLanguageWithoutCountry(),
            adtest: isPreviewDomain() ? "on" : "off",
        };

        function adLoadedCallback(
            csaContainer: HTMLDivElement,
            containerName: string,
            adsLoaded
        ): void {
            // runs after ad iframe has been loaded
            if (adsLoaded) {
                setHasAd(adsLoaded);
                try {
                    onAdLoaded(
                        csaContainer,
                        "adsense-shopping",
                        channelId,
                        {
                            personalizedAds,
                        },
                        clickUrl
                    );
                } catch (err) {
                    analytics.event("adsense-shopping", "error", channelId, true, {
                        personalizedAds,
                    });
                }
            } else {
                analytics.event("adsense-shopping", "not_loaded", channelId, true, {
                    personalizedAds,
                });
            }
        }

        // load()
        googleCsa();

        if (!isLoaded) {
            setIsLoaded(true);

            const csaContainer = divRef.current;

            if (csaContainer == null) {
                return;
            }

            const afsBlock = {
                container: csaContainer.id,
                width: csaContainer.offsetWidth,
                // number of rows
                maxTop: 1,
                enableInteractive: true,
                linkTarget: "_blank",
                adLoadedCallback: (containerName: string, adsLoaded: boolean) =>
                    adLoadedCallback(csaContainer, containerName, adsLoaded),
            };
            // request iframe
            googleCsa("ads", pageOptions, afsBlock);
        }

        if (isVisible && hasAd) {
            // send impression events
            if (impressionUrl.length > 0) {
                trackKevelEvent(impressionUrl, "false");
            }
            analytics.event("adsense-shopping", "impression", channelId, true, { personalizedAds });
        }
    }, [
        clickUrl,
        channelId,
        impressionUrl,
        hasAd,
        isLoaded, // state should update once
        isVisible, // NB. expected to change on scroll
        numberOfProducts,
        personalizedAds, // NB. might change if cookie consent
        googleStyleId,
        keyword,
    ]);

    return (
        <div className={styles.wrapper}>
            <div
                ref={divRef}
                className={clsx({
                    [styles.adContainer]: hasAd,
                })}
                id={divId}
            />
        </div>
    );
}

export default withReduxProvider(AdsenseShopping);
