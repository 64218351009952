/**
 * Defines facebook tag event names.
 * See https://developers.facebook.com/docs/facebook-pixel/api-reference#events for a list of events
 */
export default {
    EVENT_NAMES: [
        "AddToCart",
        "PageView",
        "Purchase",
        "Lead",
        "ViewContent",
        "CompleteRegistration",
    ],

    cart: {
        action: "add_item",
        label: "add_to_cart_button",
        name: "AddToCart",
    },
    page_view: {
        name: "PageView",
    },
    affiliate: {
        action: "lead",
        purchase: { name: "Purchase" },
        lead: { name: "Lead" },
    },
    view_content: {
        name: "ViewContent",
    },
    signup: {
        action: "click",
        label: "signup_button",
        name: "CompleteRegistration",
    },
    aspirer_acquisition_campaign: {
        action: "submit_signup",
        label: "successful_signup",
        name: ["CompleteRegistration", "CompleteRegistrationAspirer"],
    },
};
