import React from "react";
import { useLeadLinkClick } from "web/react/hooks/use-lead-link-click/use-lead-link-click";

interface LeadLinkProps {
    href: string;
    children?: React.ReactNode;
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
    productId?: string;
    reason: string;
    beforeOnClick?: (
        processClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => void;
    afterOnClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    className?: string;
    fromUrl?: string;
    openInNewTab?: boolean;
    type?: string;
    affiliatePromoUrl?: string;
    rel?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export function LeadLink({
    href,
    children,
    eventCategory,
    eventAction,
    eventLabel,
    productId,
    reason,
    beforeOnClick,
    afterOnClick,
    className,
    fromUrl,
    openInNewTab = false,
    type,
    affiliatePromoUrl,
    rel,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
}: LeadLinkProps): React.ReactElement {
    const { onClick, leadHref } = useLeadLinkClick({
        href,
        type,
        fromUrl,
        beforeOnClick,
        productId,
        reason,
        openInNewTab,
        eventCategory,
        eventAction,
        eventLabel,
        affiliatePromoUrl,
        afterOnClick,
    });

    return (
        // This could be considered a security issue. We need to review as we do use window.opener in some cases
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            className={className}
            href={leadHref}
            rel={rel || undefined}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            target={openInNewTab ? "_blank" : undefined}
            dangerouslySetInnerHTML={
                typeof children === "string" ? { __html: children } : undefined
            }
        >
            {typeof children !== "string" ? children : null}
        </a>
    );
}
