import clsx from "clsx";
import React from "react";
import SecondaryButton from "web/react/components/buttons/secondary-button/secondary-button";
import { LeadLink } from "web/react/components/lead-link";
import { Button } from "web/react/emo/button/button";
import { Heading } from "web/react/emo/heading";
import { useClickTracker } from "web/react/hooks/use-click-tracker/use-click-tracker";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useProductClick } from "web/react/hooks/use-product-click/use-product-click";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import {
    ProductImageCardSerializer,
    ProductRelatedProductsSidebarSerializer,
} from "web/types/serializers";
import styles from "./related-products-sidebar.module.css";

interface RelatedProductImageCardProps {
    product: ProductImageCardSerializer;
    isEmo?: boolean;
    isScrollable?: boolean | null;
}

export interface RelatedProductsSidebarProps {
    className?: string;
    relatedProductsSidebar?: ProductRelatedProductsSidebarSerializer | null;
    variant?: "vertical" | "horizontal";
    isEmo?: boolean;
}

export function RelatedProductImageCard({
    product,
    isEmo = false,
    isScrollable = false,
}: RelatedProductImageCardProps): React.ReactElement {
    const { isDesktopViewport } = useDomViewport();
    const { onClick } = useProductClick({
        reason: product.context_type,
        productSlug: product.product_slug,
        isLeadLink: !!product.lead_url,
        href: (product.lead_url || product.url) as string,
        overlayEnabled: !!product.url?.startsWith("#slug"), // activate overlays if hash URL
        openInNewTab: !!product.lead_url,
        sendAnalytics: () =>
            isDesktopViewport &&
            analytics.event("more_like_this", "click", "desktop_sidebar", false, {
                product_id: product.id,
            }),
    });

    function handleScrollToView(event): void {
        // Scroll to the third ProductCard in the RelatedProductsArea when a MLT product card image is clicked
        // When we update the design for the SimilarProducts section we can consider
        // storing the ref into context
        const targetSection = document.getElementById("relatedproducts");
        const targetRef = targetSection?.children[0].querySelector(":nth-child(1)");

        targetRef?.scrollIntoView({ behavior: "smooth" });
        event.preventDefault();
        return;
    }

    function beforeOnClick(callback, event): void {
        if (isScrollable) {
            handleScrollToView(event);
        } else {
            onClick(event);
            callback(event);
        }
    }

    function checkoutOnClick(event): void {
        if (isScrollable) {
            handleScrollToView(event);
        } else {
            onClick(event);
        }
    }

    return (
        <div className={styles.product}>
            {product.lead_url ? (
                <LeadLink
                    className={clsx(styles.cardLink, {
                        [styles.cardLinkEmo]: isEmo,
                    })}
                    href={product.lead_url}
                    reason={product.context_type}
                    productId={String(product.id)}
                    beforeOnClick={beforeOnClick}
                    rel="nofollow"
                    openInNewTab
                >
                    <img
                        className={styles.cardImage}
                        src={product.image_url}
                        height={product.image_height}
                        width={product.image_width}
                        alt={product.image_alt_text}
                    />
                </LeadLink>
            ) : (
                <a
                    className={clsx(styles.cardLink, {
                        [styles.cardLinkEmo]: isEmo,
                    })}
                    href={product.url as string}
                    onClick={checkoutOnClick}
                >
                    <img
                        className={styles.cardImage}
                        src={product.image_url}
                        height={product.image_height}
                        width={product.image_width}
                        alt={product.image_alt_text}
                    />
                </a>
            )}
        </div>
    );
}

export function RelatedProductsSidebar({
    className,
    relatedProductsSidebar,
    variant = "vertical",
    isEmo = false,
}: RelatedProductsSidebarProps): React.ReactElement | null {
    const { isDesktopViewport } = useDomViewport();
    const clickSeeMore = useClickTracker(
        "more_like_this",
        "see_more",
        isDesktopViewport ? "desktop_sidebar" : "mobile",
        true
    );

    if (!relatedProductsSidebar) {
        return null;
    }

    const SeeMoreButton = (): React.ReactElement =>
        isEmo && isDesktopViewport ? (
            <div data-testid="see-more-button">
                <Button
                    size="sm"
                    href={relatedProductsSidebar?.view_more_url}
                    onClick={clickSeeMore}
                    variant="secondary"
                    width="fit-content"
                    title={gettext("in_stock_product.see_more_related_btn_label")} // See more
                />
            </div>
        ) : (
            <div className="text-center">
                <SecondaryButton
                    small
                    href={relatedProductsSidebar.view_more_url}
                    onClick={clickSeeMore}
                    className="text-center"
                    fullWidth
                >
                    {/* See more */}
                    {gettext("in_stock_product.see_more_related_btn_label")}
                </SecondaryButton>
            </div>
        );

    return (
        <aside className={className} data-testid="related-products-sidebar">
            <section
                className={clsx({
                    [styles.sidebar]: variant === "vertical",
                    [styles.horizontal]: variant === "horizontal",
                    [styles.compact]: isEmo,
                })}
            >
                <Heading
                    textStyle="large-title-2"
                    as="h5"
                    className={clsx(styles.title, {
                        [styles.compact]: isEmo,
                    })}
                >
                    {
                        isEmo && isDesktopViewport
                            ? gettext("product.buy_area.more_like_this.label") // More like this
                            : gettext("in_stock_product.related") // Related
                    }
                </Heading>
                <div
                    className={clsx({
                        [styles.inner]: !isEmo,
                        [styles.compact]: isEmo,
                    })}
                >
                    {relatedProductsSidebar.product_image_cards.map((product) => (
                        <RelatedProductImageCard key={product.id} product={product} isEmo={isEmo} />
                    ))}
                    {variant === "vertical" && <SeeMoreButton />}
                </div>
            </section>
        </aside>
    );
}
