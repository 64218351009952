import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useNavigationContext } from "web/react/components/navigation/navigation.context";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Button } from "web/react/emo/button";
import { Flyout } from "web/react/emo/flyout/flyout";
import { ListItem } from "web/react/emo/list-item";
import { Text } from "web/react/emo/text";
import { View } from "web/react/emo/view";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import requester from "web/script/modules/requester";
import { AppPreHeaderBarCountryPickerSerializer } from "web/types/serializers";
import * as styles from "./country-selector.css";

type Country = string[];
export interface CountrySelectorProps {
    countryInfo: AppPreHeaderBarCountryPickerSerializer;
    showCountryPicker: boolean;
}

export function CountrySelector({
    countryInfo,
    showCountryPicker,
}: CountrySelectorProps): React.ReactElement {
    const { showMenu, showSearch, handleHeaderAnalytics } = useNavigationContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
    const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false);
    const [allCountries, setAllCountries] = useState([]);
    const [countrySearch, setCountrySearch] = useState("");

    const flyoutRef = useRef<HTMLDivElement>(null);

    const handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === "Backspace") {
            // Remove the last character from the word
            setCountrySearch((prevSearch) => prevSearch.slice(0, -1));
        } else if (event.key.length === 1) {
            // Only add printable characters
            setCountrySearch((prevSearch) => prevSearch + event.key);
        }
    };

    const handleSpaceBar = (event: KeyboardEvent): void => {
        if (event.code === "Space") {
            event.preventDefault(); // Prevent the use of space bar from scrolling the page
        }
    };

    async function onOpen(): Promise<void> {
        // If the picker is opened just close it
        if (isOpen) {
            setIsOpen(false);
            setCountrySearch("");
            return;
        }

        let currentCountry;

        // Fill the countries list from the backend
        // but we want to fill it once and not make this request anymore
        // so the list will be kept in state
        if (!allCountries.length) {
            const data = await requester.get("/account/get_countries/");
            setAllCountries(data.all_countries);
            currentCountry =
                data.all_countries.find((country) => country[0] === countryInfo.country) || null;
        } else {
            currentCountry =
                allCountries.find((country) => country[0] === countryInfo.country) || null;
        }

        setSelectedCountry(currentCountry);
        handleHeaderAnalytics("country_selector", "opened");

        setIsOpen(true);
    }

    useEffect(() => {
        if (isOpen) {
            // add event listener to prevent the space bar interaction with the scroll bar :S, not ideal
            window.addEventListener("keydown", handleSpaceBar);
        } else {
            window.removeEventListener("keydown", handleSpaceBar);
        }

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("keydown", handleSpaceBar);
        };
    }, [countrySearch, isOpen]);

    function onCountrySubmit(country: Country): void {
        const [countryCode, newCountry] = country;

        analytics.event("SETTINGS", "CHANGE_COUNTRY", countryInfo.country + "-to-" + newCountry);
        requester("/account/set_country/", {
            method: "POST",
            body: { country: countryCode } as any,
            headers: {
                Accept: "text/html",
            },
        }).then(function () {
            window.location.reload();
        });
    }

    return (
        <div data-testid="country-selector" ref={flyoutRef} className={styles.wrapper}>
            <button
                aria-label={gettext("header.country_picker.label")} // country picker
                onClick={onOpen}
                type="button"
                className={styles.countrySelector}
                disabled={!showCountryPicker}
            >
                <Text textStyle="body-3-small">
                    <div className={styles.countryContainer}>
                        {countryInfo.country}
                        <span className={styles.dot} />
                        {countryInfo.currency_indicator}
                    </div>
                </Text>
            </button>

            {isOpen && !showMenu && !showSearch && (
                <div className={styles.flyOutContent}>
                    <Flyout
                        isOpen={isOpen}
                        parentRef={flyoutRef}
                        handleClose={() => {
                            setIsOpen(false);
                            setSelectedCountry(null);
                        }}
                    >
                        {/* TODO: this dropdown can be separate out into a new /emo/ design system component */}
                        {/* https://jira.lystit.com/browse/CW-1445 */}
                        <View padding="md" width="full">
                            {/* Set your country */}
                            <Text textStyle="callout">{gettext("account.set_country")}</Text>
                            <div className={styles.dropdown}>
                                <div
                                    data-testid="country-selector-dropdown"
                                    className={styles.dropdownSelector}
                                    onClick={() => setIsSelectorOpen(!isSelectorOpen)}
                                    tabIndex={0}
                                    onKeyDown={handleKeyDown}
                                >
                                    <Text
                                        textStyle={"body-3-small"}
                                        color={selectedCountry ? "primary" : "secondary"}
                                    >
                                        {countrySearch
                                            ? countrySearch
                                            : // Country or Region
                                              selectedCountry[1]}
                                    </Text>
                                    <SVGIcon
                                        className={
                                            isSelectorOpen
                                                ? styles.dropdownOpenIndicator
                                                : styles.dropdownClosedIndicator
                                        }
                                        name="chevron-medium"
                                    />
                                </div>
                                <ul
                                    className={clsx(styles.dropdownOptions, {
                                        [styles.hide]: !isSelectorOpen,
                                    })}
                                >
                                    <>
                                        {allCountries.map((country) => {
                                            const isMatch = country[1]
                                                .toLowerCase()
                                                .startsWith(countrySearch.toLowerCase());
                                            if (!isMatch) return;
                                            return (
                                                <ListItem
                                                    data-testid={country[0]}
                                                    key={country[0]}
                                                    textStyle="body-3-small"
                                                    as="span"
                                                    onClick={() => {
                                                        setIsSelectorOpen(false);
                                                        setSelectedCountry(country);
                                                        // When we select the country, we reset the country search to nothing
                                                        setCountrySearch("");
                                                    }}
                                                >
                                                    {country[1]}
                                                </ListItem>
                                            );
                                        })}
                                    </>
                                </ul>
                            </div>
                            <div data-testid="set-country-button">
                                <Button
                                    title={gettext("account.set_country_btn_text")} // Set country
                                    disabled={!selectedCountry}
                                    onClick={() =>
                                        selectedCountry && onCountrySubmit(selectedCountry)
                                    }
                                    width="full"
                                />
                            </div>
                        </View>
                    </Flyout>
                </div>
            )}
        </div>
    );
}
