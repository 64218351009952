import React from "react";

import { StoryDetails } from "web/react/emo/story-details";
import { useStoryAnalytics } from "web/react/hooks/use-story-analytics";
import { PrimaryStorySerializer } from "web/types/serializers";
import * as styles from "./primary-story.css";

interface PrimaryStoryProps extends PrimaryStorySerializer {
    position: number;
    alt?: string;
}

export function PrimaryStory({
    id: componentId,
    title,
    caption,
    url,
    component_label: componentLabel,
    desktop_image_url: desktopImage,
    mobile_image_url: mobileImage,
    is_sponsored: isSponsored,
    position,
    alt,
}: PrimaryStoryProps): React.ReactElement {
    const { containerRef, sendClickEvent } = useStoryAnalytics({
        label: "primary_story",
        componentId,
        componentLabel,
        position,
        isSponsored,
    });

    return (
        <section ref={containerRef} className={styles.wrapper} data-testid={"primary-story"}>
            <div className={styles.editorialDetails}>
                <StoryDetails
                    details={{
                        isSponsored,
                        title,
                        caption,
                        url,
                    }}
                    variant="secondary"
                    onCtaClick={() => sendClickEvent("cta")}
                    captionLineLimit={6}
                    titleLineLimit={7}
                />
            </div>
            <a
                data-testid="primary-story-image"
                href={url}
                className={styles.link}
                rel="noreferrer"
                onClick={() => sendClickEvent("image")}
            >
                <picture className={styles.picture}>
                    <source
                        data-testid="mobile-image-source"
                        media={styles.mobileMedia}
                        srcSet={mobileImage}
                    />
                    <source
                        data-testid="desktop-image-source"
                        media={styles.desktopMedia}
                        srcSet={desktopImage}
                    />
                    <img
                        data-test-id="image"
                        src={mobileImage}
                        className={styles.image}
                        alt={alt}
                    />
                </picture>
            </a>
        </section>
    );
}
