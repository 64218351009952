import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MySizesAdoptionOverlay } from "web/react/components/my-sizes-adoption-overlay/my-sizes-adoption-overlay";
import OptInModal from "web/react/components/opt-in-modal/opt-in-modal";
import { QRCodeOverlay } from "web/react/components/qrcode-overlay/qrcode-overlay";
import { SoftSignupDialog } from "web/react/components/soft-signup-dialog/soft-signup-dialog";
import { EXPLICIT_EMAIL_CONSENT_COUNTRIES } from "web/react/constants";
import { openCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import { ReduxStoreState } from "web/redux/store";
import environment from "web/script/modules/environment";

export function CustomerCaptureOverlay(): JSX.Element | null {
    const dispatch = useDispatch();
    const {
        open: isOpen,
        captureType,
        blockType,
        analyticsEventCategory,
        analyticsEventLabel,
        imageSrc,
        showSocialAuth,
        legalTextKey,
        showMarketingCheckbox,
    } = useSelector((state: ReduxStoreState) => {
        return state.customerCaptureOverlay;
    });

    useEffect(() => {
        // If isSignUp is set on Lyst.environment, retrieve it
        const isSignUp = environment.get("isSignUp", false);
        // Immediately set to false after value retrieval to prevent user being reprompted for marketing email opt in
        environment.set("isSignUp", false);

        const country = environment.get("country", "");
        const countryRequiresExplicitEmailOptIn = EXPLICIT_EMAIL_CONSENT_COUNTRIES.has(country);

        if (isSignUp && countryRequiresExplicitEmailOptIn) {
            dispatch(
                openCustomerCaptureOverlay({
                    captureType: "opt_in",
                })
            );
        }
    }, []);

    if (!captureType || !isOpen) {
        return null;
    }

    if (captureType === "qr_code") {
        return <QRCodeOverlay />;
    } else if (captureType === "opt_in") {
        return <OptInModal />;
    } else if (captureType === "my_sizes_adoption_experiment") {
        return <MySizesAdoptionOverlay />;
    } else {
        return (
            <SoftSignupDialog
                analyticsEventCategory={analyticsEventCategory}
                analyticsEventLabel={analyticsEventLabel}
                captureType={captureType}
                blockType={blockType}
                overlayOpen={isOpen}
                showSocialAuth={showSocialAuth}
                imageSrc={imageSrc}
                showMarketingCheckbox={showMarketingCheckbox}
                legalTextKey={legalTextKey}
            />
        );
    }
}
